import React, { useState, useEffect } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import ContainerDimensions from 'react-container-dimensions';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Banner.module.scss';
import { PAYCO_GIFTSHOP_URL } from '../../config';
import { post } from '../../api';
import SignIn from './SignIn';

const cx = classNames.bind(styles);

const Banner = () => {
  const [state, setState] = useState({
    isLoggedIn: false,
    memberNo: null,
  });
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');

    // 엑세스 토큰 있는 경우
    if (token !== null) {
      post('member/me/sign', { token }).then(result => {
        if (result.isSuccess) {
          setState({
            isLoggedIn: true,
            memberNo: result.data.idNo,
          });
        }
      });
    }
  }, []);

  const closePanel = () => {
    setShowPanel(false);
  };

  const moveToLogin = () => {
    closePanel();
    window.open('/popup/login/', 'loginPopup', 'width=560,height=776');
  };

  const moveToGiftshop = () => {
    closePanel();
    window.open(`${PAYCO_GIFTSHOP_URL}/home`);
  };

  const confirmLoggedIn = () => {
    if (state.isLoggedIn) {
      const token = sessionStorage.getItem('access_token');
      window.open(`${PAYCO_GIFTSHOP_URL}/home?token=${token}`);
    } else {
      setShowPanel(true);
    }
  };

  return (
    <div>
      <ScreenClassRender
        render={screenClass => {
          const isMobile = ['xs', 'sm'].includes(screenClass);

          return (
            <div className={cx(['container', { mobile: isMobile }])}>
              <ContainerDimensions>
                {({ width }) => {
                  let height = width;

                  if (!isMobile) {
                    height = width / 1.5;
                    height = height > 840 ? 840 : height;
                  }

                  return <div className={cx(['illust', { mobile: isMobile }])} style={{ height }} />;
                }}
              </ContainerDimensions>
              <SignIn />
            </div>
          );
        }}
      />
      <ScreenClassRender
        render={screenClass => {
          const isMobile = ['xs', 'sm'].includes(screenClass);

          return (
            <div className={cx(['giftshopCTA', { mobile: isMobile }])}>
              <div className={cx('wrapper')}>
                <div className={cx('left')}>
                  <div className={cx('headline')}>
                    지금 당신의 가장 소중한 이에게 <span>아티제</span>를 선물하세요!
                  </div>
                  <div className={cx('logo')} />
                </div>
                <div className={cx('button')} onClick={confirmLoggedIn}>
                  <div className={cx('text')}>아티제 기프트샵</div>
                </div>
              </div>
            </div>
          );
        }}
      />
      {showPanel && (
        <div className={cx('loginOverlay')}>
          <div className={cx('panel')}>
            <div className={cx('closeButton')} onClick={closePanel}>
              <Ionicon icon="md-close" />
            </div>
            <h1>로그인 하시겠습니까?</h1>
            <p>
              로그인을 하지 않으면,
              <br />
              교환권 구매 및 선물함 이용 시<br />
              휴대폰 인증이 필요합니다.
            </p>
            <div className={cx('button')} onClick={moveToLogin}>
              로그인
            </div>
            <div className={cx('message')} onClick={moveToGiftshop}>
              비로그인 상태로 이용할래요.
            </div>
            <div className={cx('guide')}>
              클럽아티제 회원이 되시면 구매 금액 적립 등<br />
              다양한 혜택을 누리실 수 있습니다.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
