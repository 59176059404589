import React from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import ContainerDimensions from 'react-container-dimensions';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Cake.module.scss';
import SectionTitle from '../misc/SectionTitle';
import Paragraph from '../misc/Paragraph';

const cx = classNames.bind(styles);

const Cake = () => (
  <div className={cx('container')}>
    <Container>
      <div className={cx('illustClip')}></div>
      <Row align="center">
        <Col xs={12} lg={4.5} className={cx('column')}>
          <ScreenClassRender render={(screenClass) => {
            const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

            return (
              <div className={cx(['content', { center: isMobile }])}>
                <SectionTitle
                  title="Cake"
                  subtitle={<span>정성이 필요한 순간,<br/>아티제 케이크와 함께하세요.</span>}
                />
                <Paragraph>아티제 케이크는 엄선된 최고의 재료만을 사용합니다.<br/>특히 신선한 1등급 우유로 만든 생크림을 사용하여 훨씬 부드럽고 깔끔한 맛을 느낄 수 있습니다.</Paragraph>
                <Link to="/menu/bakery/" className={cx('readMore')}>
                  <div className={cx('text')}>베이커리 전체보기</div>
                  <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                </Link>
              </div>
            );
          }} />
        </Col>
        <Col xs={12} lg={7} offset={{ lg: .5 }} className={cx(['column', 'playerWrapper'])}>
          <ContainerDimensions>
            {({ width }) => {
              const height = (width - 8) * 360 / 640; // ratio 640 : 360

              return (
                <div style={{ height }}>
                  <iframe className={cx('player')} title="아티제 케이크" width={width - 8} height={height} src="https://player.vimeo.com/video/279679178?background=1" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
                </div>
              );
            }}
          </ContainerDimensions>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Cake;
