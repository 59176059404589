import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ScreenClassRender } from 'react-grid-system';
import styles from './SectionTitle.module.scss';

const cx = classNames.bind(styles);

const SectionTitle = ({ title, subtitle, center }) => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

    return (
      <div className={cx(['container', { center: center || isMobile }])}>
        <div className={cx('title')}>{title}</div>
        <div className={cx('subtitle')}>{subtitle}</div>
      </div>
    );
  }} />
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object.isRequired,
  center: PropTypes.bool,
};

SectionTitle.defaultProps = {
  center: false,
};

export default SectionTitle;