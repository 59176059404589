import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ScreenClassRender } from 'react-grid-system';
import styles from './Paragraph.module.scss';

const cx = classNames.bind(styles);

const Paragraph = ({ children }) => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);
    const noBr = ['xs', 'sm', 'md'].includes(screenClass);

    return (
      <div className={cx(['paragraph', { mobile: isMobile }, { noBr }])}>{children}</div>
    );
  }} />
);

Paragraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default Paragraph;