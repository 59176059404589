import React from 'react';
import PropTypes from 'prop-types';
import ContainerDimensions from 'react-container-dimensions';
import classNames from 'classnames/bind';
import styles from './ScaleImageCell.module.scss';

const cx = classNames.bind(styles);

const ScaleImageCell = ({ image, ratio, className, children }) => (
  <div className={className}>
    <ContainerDimensions>
      {({ width }) => {
        const height = width * ratio;

        return (
          <div className={cx('cell')} style={{ height }}>
            <div className={cx('scaleArea')} style={{ height }}>
              <div className={cx('image')} style={{ backgroundImage: `url(${image})` }} />
            </div>
            {children}
          </div>
        );
      }}
    </ContainerDimensions>
  </div>
);

ScaleImageCell.propTypes = {
  image: PropTypes.string.isRequired,
  ratio: PropTypes.number,
};

ScaleImageCell.defaultProps = {
  ratio: 1,
};

export default ScaleImageCell;