import React, { Component } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';

import styles from './SignIn.module.scss';
import { post, logout } from 'api';
import Grade from '../misc/Grade';

const cx = classNames.bind(styles);

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
      name: null,
    };
  }

  async componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 1. 엑세스토큰 있는 경우
    if (token !== null) {
      const result = await post('member/me/sign', { token });
      if (result.isSuccess) {
        this.setState({
          isLoggedIn: true,
          name: result.data.name,
        });
      } else {
        this.setState({ isLoggedIn: false });
      }
    }
    // 2. 엑세스토큰 없는 경우
    else {
      this.setState({ isLoggedIn: false });
    }
  }

  render() {
    const { isLoggedIn, name } = this.state;

    // 로그인 전
    if (isLoggedIn !== null && !isLoggedIn) {
      return (
        <ScreenClassRender
          render={screenClass => {
            const isMobile = ['xs', 'sm'].includes(screenClass);
            const overlay = ['md', 'lg', 'xl'].includes(screenClass);

            return (
              <div className={cx(['signInPanel', { overlay }])}>
                <Container className={cx('container')}>
                  <Row>
                    <Col>
                      <div className={cx('padding')}>
                        <Container>
                          <Row align="center">
                            <Col xs={12} md={8} className={cx('column')}>
                              <div className={cx(['messageWrapper', { center: isMobile }])}>
                                <div className={cx(['message', { center: isMobile }])}>
                                  로그인 하시면 클럽아티제의 다양한 서비스 혜택을 받으실 수 있습니다.
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={4} className={cx('column')}>
                              <div className={cx(['buttons', { center: isMobile }])}>
                                <div className={cx('button')} onClick={this.login}>
                                  <div className={cx('text')}>로그인</div>
                                </div>
                                <div className={cx('button')} onClick={this.join}>
                                  <div className={cx('text')}>회원가입</div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          }}
        />
      );
    }
    // 로그인 후
    else if (isLoggedIn !== null && isLoggedIn) {
      return (
        <ScreenClassRender
          render={screenClass => {
            const isMobile = ['xs', 'sm'].includes(screenClass);
            const overlay = ['md', 'lg', 'xl'].includes(screenClass);

            return (
              <div className={cx(['signInPanel', { overlay }])}>
                <Container className={cx('container')}>
                  <Row>
                    <Col>
                      <div className={cx('padding')}>
                        <Container>
                          <Row align="center">
                            <Col xs={12} md={8} className={cx('column')}>
                              <div className={cx(['messageWrapper', { center: isMobile }])}>
                                <div className={cx(['message', { center: isMobile }])}>
                                  반갑습니다. <span className={cx('bold')}>{name}</span>님의 회원등급은 현재{' '}
                                  <span className={cx(['bold', 'orange'])}>
                                    <Grade />
                                  </span>
                                  입니다.
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={4} className={cx('column')}>
                              <div className={cx(['buttons', { center: isMobile }])}>
                                <Link to="/club-artisee/my-artisee" className={cx('button')}>
                                  <div className={cx('text')}>마이 아티제</div>
                                </Link>
                                <div className={cx('button')} onClick={this.logout}>
                                  <div className={cx('text')}>로그아웃</div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          }}
        />
      );
    }

    return null;
  }

  // 로그인
  login = () => {
    window.open('/popup/login/', 'loginPopup', 'width=560,height=776');
  };

  // 회원가입
  join = () => {
    window.open('/popup/join/', 'joinPopup', 'width=600,height=700');
  };

  // 로그아웃
  logout = async () => {
    const logoutResult = await logout();

    // success
    if ('logoutStatus' in logoutResult && logoutResult.logoutStatus === 'LOGOUT_SUCCESS') {
      sessionStorage.clear();
      window.location.replace('/');
    }
    // failure
    else {
      alert('예기지 않은 문제가 발생하였습니다.');
    }
  };
}
