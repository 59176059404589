import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { userActions } from '../../actions';

class Grade extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.user;

    // 응답을 정상적으로 수신한 경우
    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      // 등급명이 변화가 있는 경우
      if (prevState.gradeName !== data.result.memberGradeName) {
        return {
          gradeName: data.result.memberGradeName,
        };
      }
    }
    
    return null;
  }

  constructor(props) {
    super(props);
    
    const { isProcessing, failure, data } = props.user;
    let gradeName;

    // 이미 회원등급 정보를 정상적으로 획득하여 가지고 있는 경우
    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      gradeName = data.result.memberGradeName;
    }

    this.state = {
      gradeName,
    };
  }

  componentDidMount() {
    const { isProcessing, failure, data } = this.props.user;

    // 이미 회원등급 정보를 정상적으로 획득하여 가지고 있는 경우
    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      return;
    }

    const token = sessionStorage.getItem('access_token');
    if (token !== null) {
      this.props.actions.userActions.request({ token });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.user;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    return (
      <span>{this.state.gradeName}</span>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      userActions: bindActionCreators(userActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Grade);