import React from 'react';

import Banner from '../components/Index/Banner';
import News from '../components/Index/News';
import Cake from '../components/Index/Cake';
import Coffee from '../components/Index/Coffee';
import OtherMenu from '../components/Index/OtherMenu';
// import Popup from '../components/Index/Popup';

const IndexPage = () => (
  <div>
    <Banner />
    <News />
    <Cake />
    <Coffee />
    <OtherMenu />
    {/* <Popup /> */}
  </div>
);

export default IndexPage;
