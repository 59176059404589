import React from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Coffee.module.scss';
import ScaleImageCell from '../misc/ScaleImageCell';
import SectionTitle from '../misc/SectionTitle';
import Paragraph from '../misc/Paragraph';

const cx = classNames.bind(styles);

const ImageColumn = <ScaleImageCell image={require('assets/images/main/coffee-story.jpg')} ratio={9 / 16} />;

const Coffee = () => (
  <ScreenClassRender
    render={screenClass => {
      const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

      return (
        <Container className={cx('container')}>
          <Row align="center">
            {!isMobile && (
              <Col lg={7} className={cx('column')}>
                {ImageColumn}
              </Col>
            )}

            <Col xs={12} lg={4.5} offset={{ lg: 0.5 }} className={cx('column')}>
              <div className={cx(['content', { center: isMobile }])}>
                <SectionTitle title="Coffee" subtitle={<span>아티제 바리스타가 직접 선택한 블렌드</span>} />
                <Paragraph>
                  수십 가지 블렌드 후보 중 아티제 바리스타들이 직접 선택한 아티제 시그니처 블렌드는 살구의 은은한 산미와
                  견과류의 고소함이 느껴지는 부드럽고 깔끔한 블렌드로 모두 스페셜티 커피만을 사용하여 황홀한 맛과 향이
                  어우러져 피어납니다.
                </Paragraph>
                <div className={cx(['buttons', { vertical: isMobile }])}>
                  <Link to="/menu/beverage/" className={cx('readMore')}>
                    <div className={cx('text')}>음료 전체보기</div>
                    <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                  </Link>
                  <Link to="/the-story/coffee-story/" className={cx('readMore')}>
                    <div className={cx('text')}>커피 스토리 바로가기</div>
                    <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                  </Link>
                </div>
              </div>
            </Col>

            {isMobile && (
              <Col xs={12} className={cx('column')}>
                {ImageColumn}
              </Col>
            )}
          </Row>
          <div className={cx('clipWrapper')}>
            <div className={cx('illustClip')}></div>
          </div>
        </Container>
      );
    }}
  />
);

export default Coffee;
