import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from './News.module.scss';
import { recentActions } from '../../actions';
import ScaleImageCell from '../misc/ScaleImageCell';
import SectionTitle from '../misc/SectionTitle';
import ArticleTitle from '../misc/ArticleTitle';

const cx = classNames.bind(styles);

class News extends Component {
  componentDidMount() {
    if (this.props.recent.data.length === 0) {
      this.props.actions.recentActions.request();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure } = nextProps.recent;
    return !isProcessing && !failure;
  }

  render() {
    const { recent } = this.props;

    return (
      <Container className={cx('container')}>
        <SectionTitle title="News" subtitle={<span>아티제의 새로운 소식과 이벤트를 확인하세요.</span>} center={true} />
        <Row className={cx('grid')}>
          <ScreenClassRender
            render={screenClass => {
              const isSimpleDate = ['xs', 'md'].includes(screenClass);

              return recent.data.map((item, i) => {
                const { type, id, title, thumb_image, start_date, end_date } = item;

                let fromDate;
                let toDate;
                let date;

                switch (type) {
                  case 'new':
                    fromDate = moment(start_date).format('YYYY.MM.DD');
                    toDate = end_date ? moment(end_date).format('YYYY.MM.DD') : '상시';
                    date = isSimpleDate
                      ? end_date === null
                        ? `${fromDate} 부터`
                        : `${toDate} 까지`
                      : `${fromDate} ~ ${toDate}`;
                    break;

                  case 'event':
                    fromDate = moment(start_date).format('YYYY.MM.DD');
                    toDate = moment(end_date).format('YYYY.MM.DD');
                    date = isSimpleDate ? `${toDate} 까지` : `${fromDate} ~ ${toDate}`;
                    break;

                  default:
                    break;
                }

                return (
                  <Col xs={6} md={3} key={`${type}-${id}`} className={cx('column')}>
                    <div className={cx('cell')}>
                      <Link to={`/news/${type}/view/?${id}`}>
                        {thumb_image !== null && <ScaleImageCell image={thumb_image.url} />}
                        {thumb_image === null && <ScaleImageCell image="https://dummyimage.com/270x270/000/fff" />}
                        <ArticleTitle title={title} date={date} />
                      </Link>
                    </div>
                  </Col>
                );
              });
            }}
          />
        </Row>
      </Container>
    );
  }
}

/**
 * Define redux settings
 */
const mapStateToProps = state => {
  const { recent } = state;
  return {
    recent,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      recentActions: bindActionCreators(recentActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
