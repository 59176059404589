import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './ArticleTitle.module.scss';

const cx = classNames.bind(styles);

const ArticleTitle = ({ title, date }) => (
  <div className={cx('container')}>
    <div className={cx('title')}>{title}</div>
    <div className={cx('date')}>{date}</div>
  </div>
);

ArticleTitle.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default ArticleTitle;