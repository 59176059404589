import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';

import styles from './OtherMenu.module.scss';
import ScaleImageCell from '../misc/ScaleImageCell';
import SectionTitle from '../misc/SectionTitle';

const cx = classNames.bind(styles);

const OtherMenu = () => (
  <div className={cx('container')}>
    <Container>
      <SectionTitle
        title="Other Menu"
        subtitle={<span>아티제의 더 다양한 메뉴를 즐겨보세요.</span>}
        center={true}
      />
      <Row className={cx('grid')}>
        <Col xs={6} md={3} className={cx('column')}>
          {/* Beverage */}
          <Link to="/menu/beverage/">
            <div className={cx('thumb')}>
              <ScaleImageCell image={require('assets/images/main/menu-beverage.png')} />
            </div>
            <div className={cx('titleEng')}>Beverage</div>
            <div className={cx('titleKor')}>음료</div>
          </Link>
        </Col>
        <Col xs={6} md={3} className={cx('column')}>
          {/* Dessert */}
          <Link to="/menu/dessert/">
            <div className={cx('thumb')}>
              <ScaleImageCell image={require('assets/images/main/menu-dessert.png')} />
            </div>
            <div className={cx('titleEng')}>Dessert</div>
            <div className={cx('titleKor')}>디저트</div>
          </Link>
        </Col>
        <Col xs={6} md={3} className={cx('column')}>
          {/* Food */}
          <Link to="/menu/food/">
            <div className={cx('thumb')}>
              <ScaleImageCell image={require('assets/images/main/menu-food.png')} />
            </div>
            <div className={cx('titleEng')}>Food</div>
            <div className={cx('titleKor')}>푸드</div>
          </Link>
        </Col>
        <Col xs={6} md={3} className={cx('column')}>
          {/* Retail */}
          <Link to="/menu/retail/">
            <div className={cx('thumb')}>
              <ScaleImageCell image={require('assets/images/main/menu-retail.png')} /> 
            </div>
            <div className={cx('titleEng')}>Retail</div>
            <div className={cx('titleKor')}>상품</div>
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
);

export default OtherMenu;
